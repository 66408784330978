import Vue from "vue";
import CreateTabs from "@/views/Admin/Segments/Create/createTab.vue";
import Overview from "@/views/Admin/Segments/Create/overview.vue";
import OverviewEdit from "@/views/Admin/Segments/Edit/overviewEdit.vue";
import IndexCategory from "@/views/Admin/Segments/Categories/index.vue";
import Alertize from "@/components/Alertize.vue";
import { SortingOption } from "@/interfaces/paginated";
import { initSegments } from "@/utils/initData";
import {
	SegmentDataCreate,
	SegmentTag,
	SegmentFilter,
} from "@/interfaces/segment";
import { Notification, MessageTypes } from "@/interfaces/proccess";
import { SegmentCategoryFilter } from "@/interfaces/segment_category";
import { isNull, isUndefined } from "lodash";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
	name: "Create",

	props: {},

	components: { CreateTabs, Alertize, Overview, IndexCategory, OverviewEdit },

	data: () => ({
		title: "Create",
		currentTab: 0,
		items: [],
		segmentCategoriesList: [],
		segment: {},
		params_segment_category: {
			filters: {} as SegmentCategoryFilter,
			options: { sort: "name", order: "desc" } as SortingOption,
		},
		tag_generated: "",
	}),

	created() {
		this.$nextTick(async () => {
			try {
				this.setLoadingData(TypeLoading.loading);
                await this.setLoadingField(true);

				const isEdit: Boolean = this.hasID();

				this.segment = await this.getSegmentInit(isEdit);

				// carga de recursos
				await this.loadResources();

				if (isEdit) {
					// carga de recursos para la edicion
					this.updateSelectedTabIndex();
					this.loadEditResources();
				}

                await this.setLoadingField(false);
				await this.setLoadingData();
			} catch (error) {
                await this.setLoadingField(false);
				await this.setLoadingData();
			}
		});
	},

	async mounted() {},

	beforeDestroy() {
		this.$store.state.custom_list.verb_usage_list = [];
	},

	computed: {
		...mapGetters("loading", ["isLoading"]),

		getItems() {
			return this.updateItems;
		},

		updateItems() {
			this.items = [
				{
					key: 0,
					tab: "Segment",
					disabled: false,
				},
				{
					key: 1,
					tab: "Categories",
					disabled: this.isIDSegment,
				},
			];
			return this.items;
		},

		getAdvertisers() {
			return this.$store.state.advertiser.advertisers_list;
		},

		getVerbUsage() {
			return this.$store.state.custom_list.verb_usage_list;
		},

		getSegmentTagType() {
			return this.$store.state.custom_list.segment_tag_type_list;
		},

		getSegmentCategories() {
			return this.segmentCategoriesList;
		},

		isIDSegment() {
			return isNull(this.segment.id) || isUndefined(this.segment.id);
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),

		async getSegmentInit(isEdit: Boolean) {
			return isEdit
				? await this.getSegmentByID(this.getID())
				: initSegments();
		},

		getID(): Number {
			return Number(this.$route.params.id);
		},

		hasID() {
			return !!this.$route.params.id;
		},

		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{
					root: true,
				}
			);
		},

		redirectTo() {
			this.setNotification({ title: "", message: "", type: "" });
			this.$router.push({ name: "SegmentsIndex" });
		},

		updateSelectedTabIndex(index: number = 0) {
			this.currentTab = index;
		},

		currentTabSelected(item: String, selected: String) {
			return item === selected;
		},

		updateModelOverview(model: any) {
			this.campaign = model;
		},

		getMsgSuccess(redirec: boolean, to: string, message: string) {
			let msgSucces = {
				title: "Success",
				message: message,
				type: MessageTypes.SUCCESS,
				show: true,
				details: "",
				to: redirec ? to : undefined,
				btn_text: "Close",
			};
			return msgSucces;
		},

		handleCancel() {
			this.$router.push({ name: "SegmentsIndex" });
		},

		isCurrentTabSelected(item: { key: number }, index: number): Boolean {
			return item.key === index;
		},

		async setLoadingField(loading: boolean = false) {
			return this.$store.dispatch("proccess/setLoadingField", loading);
		},

		async loadResources() {
			await this.dispatchAdvertisers();
			await this.dispatchVerbUsages();
			await this.fetchSegmentCategory();
			await this.setLoadingData();
		},

		async loadEditResources() {
			await this.dispatchTagTypes();
			await this.dispatchSegmentCategory();
		},

		async getSegmentByID(id: Number) {
			return await this.$store.dispatch("segment/show", id);
		},

		async generatedTagSegment(_id_segment: number, _id_tag: number) {
			try {
				this.tag_generated = "";

				const params = {
					id_segment: _id_segment,
					id_tag: _id_tag,
				} as SegmentTag;

				await this.$store
					.dispatch("segment/getGeneratedTag", params, { root: true })
					.then((result: any) => {
						this.tag_generated = result.tag;
					})
					.catch(async (error) => {
						this.tag_generated = "";
						await this.setLoadingData();
					});
			} catch (error) {
				this.tag_generated = "";
			}
		},

		async handleSubmit(params: {
			segment: SegmentDataCreate;
			redirect: boolean;
		}) {
			if (isUndefined(params.segment.id)) {
				this.handleSubmitSave(params);
			} else {
				this.handleSubmitUpdate(params);
			}
		},

		async handleSubmitCategories(params: {
			segment: SegmentDataCreate;
			redirect: boolean;
		}) {
			this.handleSubmitUpdate(params);
		},

		async handleSubmitAndGeneratedTag(params: {
			segment: SegmentDataCreate;
			id_tag: number;
		}) {
			if (isUndefined(this.segment.id) || isNull(this.segment.id)) {
				try {
					this.setLoadingData(TypeLoading.loading);
					this.dispatchCreateSegment(params.segment)
						.then(async (result: any) => {
							this.segment = result;
							this.generatedTagSegment(
								this.segment.id,
								params.id_tag
							);
							this.setNotification(
								this.getMsgSuccess(
									false,
									"",
									"Your changes have been saved successfully"
								)
							);
							this.dispatchSegmentCategory().then(
								(result: any) => {
									this.segmentCategoriesList = result;
								}
							);
							await this.setLoadingData();
						})
						.catch(async (err) => {
							console.error("handleSubmit", { error: err });
							await this.setLoadingData();
						});
				} catch (error) {
					console.error("handleSubmit", { error: error });
					await this.setLoadingData();
				}
			} else {
				try {
					await this.setLoadingData(TypeLoading.loading);
					await this.generatedTagSegment(
						this.segment.id,
						params.id_tag
					);
					await this.setLoadingData();
				} catch (error) {
					await this.setLoadingData();
				}
			}
		},

		async handleSubmitGeneratedTag(params: {
			segment_id: number;
			id_tag: number;
		}) {
			try {
				await this.setLoadingData(TypeLoading.loading);
				await this.generatedTagSegment(
					params.segment_id,
					params.id_tag
				);
				await this.setLoadingData();
			} catch (error) {
				console.error("handleSubmit", { error: error });
				await this.setLoadingData();
			}
		},

		async handleSubmitSave(params: {
			segment: SegmentDataCreate;
			redirect: boolean;
		}) {
			try {
				this.setLoadingData(TypeLoading.loading);
				if (params.redirect) {
					await this.dispatchCreateSegment(params.segment)
						.then(async (result: any) => {
							this.segment = result;
							this.setNotification(
								this.getMsgSuccess(
									false,
									"",
									"Your changes have been saved successfully"
								)
							);
							this.dispatchSegmentCategory().then(
								(result: any) => {
									this.segmentCategoriesList = result;
									this.updateSelectedTabIndex(1);
								}
							);
							await this.setLoadingData();
						})
						.catch(async (err) => {
							console.error("handleSubmit", { error: err });
							await this.setLoadingData();
						});
				} else {
					await this.dispatchCreateSegment(params.segment)
						.then((result: any) => {
							this.segment = result;
							this.setNotification(
								this.getMsgSuccess(
									true,
									"SegmentsIndex",
									"Your changes have been saved successfully"
								)
							);
						})
						.catch(async (err) => {
							console.error("handleSubmit", { error: err });
							await this.setLoadingData();
						});
				}
				await this.setLoadingData();
			} catch (error) {
				console.error("handleSubmit", { error: error });
				await this.setLoadingData();
			}
		},

		async handleSubmitUpdate(params: {
			segment: SegmentDataCreate;
			redirect: boolean;
		}) {
			try {
				this.setLoadingData(TypeLoading.loading);
				if (params.redirect) {
					await this.dispatchUpdateSegment(params.segment)
						.then(async (result: any) => {
							this.segment = result;
							this.setNotification(
								this.getMsgSuccess(
									false,
									"",
									"Your changes have been saved successfully"
								)
							);
							this.dispatchSegmentCategory().then(
								(result: any) => {
									this.segmentCategoriesList = result;
									this.updateSelectedTabIndex(1);
								}
							);
							await this.setLoadingData();
						})
						.catch(async (err) => {
							console.error("handleSubmit", { error: err });
							await this.setLoadingData();
						});
				} else {
					await this.dispatchUpdateSegment(params.segment)
						.then((result: any) => {
							this.segment = result;
							this.dispatchSegmentCategory().then(
								(result: any) => {
									this.segmentCategoriesList = result;
								}
							);
							this.setNotification(
								this.getMsgSuccess(
									true,
									"SegmentsIndex",
									"Your changes have been saved successfully"
								)
							);
						})
						.catch(async (err) => {
							console.error("handleSubmit", { error: err });
							await this.setLoadingData();
						});
				}
				await this.setLoadingData();
			} catch (error) {
				console.error("handleSubmit", { error: error });
				await this.setLoadingData();
			}
		},

		/*
		 * Metodos Fetch
		 */

		async fetchAdvertiser() {
			try {
				await this.setLoadingField(true);
				await this.dispatchAdvertisers();
				await this.setLoadingField(false);
			} catch (error) {
				await this.setLoadingField(false);
			}
		},

		async fetchVerbUsages() {
			try {
				await this.setLoadingField(true);
				await this.dispatchVerbUsages();
				await this.setLoadingField(false);
			} catch (error) {
				await this.setLoadingField(false);
			}
		},

		async fetchTagTypes() {
			try {
				await this.setLoadingField(true);
				await this.dispatchTagTypes();
				await this.setLoadingField(false);
			} catch (error) {
				await this.setLoadingField(false);
			}
		},

		async fetchSegmentCategory() {
			try {
				await this.setLoadingField(true);
				await this.dispatchSegmentCategory()
					.then((result: any) => {
						this.segmentCategoriesList = result;
					})
					.catch((err) => {});
				await this.setLoadingField(false);
			} catch (error) {
				await this.setLoadingField(false);
			}
		},

		/*
		 * Metodos Dispatch
		 */

		async dispatchAdvertisers() {
			return this.$store.dispatch("advertiser/list", {
				filters: { active: true } as SegmentFilter,
				options: { sort: "name", order: "desc" } as SortingOption,
			});
		},

		async dispatchVerbUsages() {
			return this.$store.dispatch("custom_list/getVerbUsage", {});
		},

		async dispatchTagTypes() {
			return this.$store.dispatch("custom_list/getSegmentTagType", {});
		},

		async dispatchCreateSegment(segment: SegmentDataCreate) {
			let params = {
				seg: segment,
			};
			return this.$store.dispatch("segment/create", params, {
				root: true,
			});
		},

		async dispatchUpdateSegment(segment: SegmentDataCreate) {
			let params = {
				seg: segment,
			};
			return this.$store.dispatch("segment/update", params, {
				root: true,
			});
		},

		async dispatchSegmentCategory() {
			if (isNull(this.segment.advertiser_id)) {
				this.params_segment_category.filters = {};
			} else {
				this.params_segment_category.filters = {
					advertiser_id: this.segment.advertiser_id,
				};
			}
			return this.$store.dispatch(
				"segment_category/all",
				this.params_segment_category,
				{
					root: true,
				}
			);
		},
	},
});
